import i18n from "../../../i18n";
import {
  EventTypes,
  i18textByEventType,
  sortAlphabetical,
} from "../../../helpers/constants";
import { IUnit } from "../../../services/dashboard.services";
import { INotificationHeaderCell } from "../helpers";
import { IUserDetails } from "../../../services/header.services";

export const getNotificationFilterOptions = () => {
  const filterableEvents = [
    EventTypes.bathroom_entry,
    EventTypes.bathroom_anomaly,
    EventTypes.bathroom_visit_count,
    EventTypes.activity_monitor_day,
    EventTypes.exterior_door_open,
    EventTypes.help_button_press,
    EventTypes.activity_monitor_kitchen,
    EventTypes.activity_monitor_night,
    EventTypes.night_bathroom_overstay,
    EventTypes.night_bathroom_aggregate_time,
    EventTypes.night_bathroom_visit_count,
    EventTypes.refrigerator_door_open,
    EventTypes.site_exit,
    EventTypes.security_notification,
    EventTypes.pause_notification,
    EventTypes.heat_index_warning,
    EventTypes.no_morning_motion,
    EventTypes.hand_off_notification,
    EventTypes.exterior_exit,
    EventTypes.fall_notification,
  ];
  const options = Object.keys(EventTypes)
    .filter((eventType) => filterableEvents.includes(eventType))
    .sort((a, b) =>
      sortAlphabetical(
        i18n.t(i18textByEventType(a)),
        i18n.t(i18textByEventType(b))
      )
    )
    .map((eventType) => {
      return {
        id: eventType,
        label: i18n.t(i18textByEventType(eventType)),
      };
    });

  return options;
};

export const getNotificationDefaultState = () => {
  const notificationDefaultState: { [key: string]: boolean } = {};
  const notificationOptions = getNotificationFilterOptions();
  // Create controlled list options
  notificationOptions.forEach((option) => {
    if (option.id === EventTypes.hand_off_notification) {
      notificationDefaultState[option.id] = false;
    } else {
      notificationDefaultState[option.id] = true;
    }
  });
  return notificationDefaultState;
};

export const getFilterDefaultValues = (
  filterableColumns: INotificationHeaderCell[],
  user: IUserDetails[] | undefined,
  units: IUnit[] | undefined
) => {
  const notificationDefaultState = getNotificationDefaultState();

  // Create controlled list options
  const unitDefaultState: { [key: string]: boolean } = {};
  user?.forEach((unit) => (unitDefaultState[unit.id] = true));

  // Create controlled list options
  const responderDefaultState: { [key: string]: boolean } = {};
  units?.forEach((user) => (responderDefaultState[user.id] = true));

  const response: { [key: string]: { [key: string]: boolean } } = {};

  filterableColumns.forEach((column) => {
    if (column.id === "notification") {
      response[column.id] = notificationDefaultState;
    }
    if (column.id === "unit" || column.id === "resident") {
      response[column.id] = unitDefaultState;
    }
    if (column.id === "responder") {
      response[column.id] = responderDefaultState;
    }
  });

  return response;
};
