import { useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { AnalyticsPrintableViewer } from "./AnalyticsPrintableViewer";
import { useAppSelector } from "../app/appHooks";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    analyticsContainer: {
      display: "flex",
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight})`,
    },
    analyticsViewerContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      overflowX: "hidden",
    },
    [`@media print`]: {
      analyticsContainer: {
        height: "100%",
      },
      analyticsViewerContainer: {
        overflowY: "hidden",
      },
    },
  })
);

export default function AnalyticsPrintable() {
  const showBanner = useAppSelector((state) => state.headerState.showBanner);

  /* ---- Hooks ---- */
  const classes = useStyles(showBanner);

  /* ---- Refs ---- */
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={classes.analyticsContainer}>
      <div className={classes.analyticsViewerContainer} id="viewer-container">
        <AnalyticsPrintableViewer containerRef={containerRef} />
      </div>
    </div>
  );
}
