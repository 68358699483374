import React, { useEffect, useMemo, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Popover } from "@material-ui/core";
import { roleLabels, RolePicker } from "./common/RolePicker";
import { FormRow } from "./common/FormRow";
import { PhoneNumberField } from "../common/PhoneNumberField";
import { NotificationGroupsPicker } from "./common/NotificationGroupsPicker";
import { ICommunityGroup, IUserDetails } from "../../services/header.services";
import { AccessibleUnitsPicker } from "./common/AccessibleUnitsPicker";
import { IUnit } from "../../services/dashboard.services";
import {
  getResidentName,
  isHelpAtHome,
  isActiveUser,
} from "../dashboard/dashboardSlice";
import {
  CommunityTypes,
  RoleTypes,
  sortAlphabetical,
} from "../../helpers/constants";
import {
  IUpdateCommunityUserData,
  IUpdateRoleData,
} from "../../services/settings.services";
import {
  deleteCommunityUser,
  resendInvitation,
  updateCommunityUser,
} from "../app/asyncThunkActions";
import i18n from "../../i18n";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ConfirmDialog, DialogTypes } from "../common/ConfirmDialog";
import { ErrorCodes } from "../../services/constants";
import { handledError, showSoftNotification } from "../app/appSlice";
import { changeSelectedCommunity } from "../header/headerSlice";
import store from "../../helpers/store";
import {
  DateTimeDiffHoursDays,
  DateTimeRelativeYearFormat,
} from "../../helpers/datetime";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    EditUserContainer: {
      padding: theme.spacing(6, 6),
      width: "550px",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
    },
    sectionTitle: {
      position: "relative",
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    wrapper: {
      listStyle: "none",
      padding: 0,
      borderRadius: "3px",
      paddingRight: "20px",
    },
    rowInput: {
      flex: "1.5",
      fontSize: "1rem",
    },
    formRow: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: ".5em",
      "& > label": {
        padding: ".5em 1em .5em 0",
        flex: " 1",
        textAlign: "right",
        color: theme.palette.paused.main,
      },
    },
    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
      minWidth: "120px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputWidth: {
      width: "100%",
    },
    moreButton: {
      position: "absolute",
      right: 0,
      top: "-12px",
    },
    lastActiveBox: {
      display: "flex",
    },
    timeDifference: {
      marginLeft: "10px",
      color: theme.palette.gray.main,
    },
  })
);

interface FormState {
  role: string | null;
  notification_groups: string[];
  accessible_units: string[];
}

const checkAllUnits = (accesibleUnits: string[], units: string[]) => {
  return accesibleUnits.sort().join(",") === units.sort().join(",");
};

export function EditCommunityUser() {
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* State */
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverID, setPopoverID] = useState("");
  const [userValues, setUserValues] = useState<FormState>({
    role: null,
    notification_groups: [],
    accessible_units: [],
  });
  const [allUnits, setAllUnits] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const [deleteUser, setDeleteUser] = useState(false);

  let updateRole = false;
  let updateUser = false;

  /* Selectors */
  const selectedUser = useAppSelector((state) =>
    state.headerState.users?.find(
      (user) => user.id === state.settingsState.selectedUser
    )
  );
  const currentUserEmail = useAppSelector(
    (state) => state.headerState.user?.email
  );
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );
  const errorCode = useAppSelector((state) => state.settingsState.errorCode);
  const timeZone = useAppSelector(
    (state) => state.headerState.selectedCommunity?.time_zone
  );

  const installerRole = useAppSelector(
    (state) => state.headerState.installerRole
  );

  const userRoles = useAppSelector((state) => {
    const communityType = state.headerState.selectedCommunity?.community_type;
    if (communityType === undefined) return undefined;
    if (state.headerState.userRoles === undefined) return undefined;

    if (communityType === CommunityTypes.pro) {
      // remove pro: from family
      return state.headerState.userRoles.filter(
        (role) => role.name !== RoleTypes.family
      );
    } else if (communityType === CommunityTypes.aip) {
      return state.headerState.userRoles.filter(
        (role) => role.name !== RoleTypes.pro_family
      );
    }
    return state.headerState.userRoles;
  });

  const communityGroups = useAppSelector((state) => {
    if (state.headerState.communityGroups !== undefined) {
      return JSON.parse(
        JSON.stringify(state.headerState.communityGroups)
      ) as ICommunityGroup[];
    }
    return state.headerState.communityGroups;
  })?.sort((a, b) => sortAlphabetical(a.name, b.name));

  const units = useAppSelector((state) => state.headerState.units);
  /* Effects */
  useEffect(() => {
    if (selectedUser !== undefined) {
      let roleOption: string | null = null;
      if (selectedUser.role.name) {
        roleOption =
          userRoles?.find((role) => role.name === selectedUser.role.name)
            ?.name ?? null;
      }

      let notificationGroups: string[] = [];
      if (selectedUser.groups) {
        if (roleOption !== RoleTypes.pro_family) {
          notificationGroups = selectedUser.groups.map((group) => group.id);
        }
      }

      const selectedUserUnitIDs =
        selectedUser.units.map((unit) => unit.id) ?? [];
      const unitIDs = units?.map((unit) => unit.id) ?? [];

      // If user.role.all_units is null or true, user has access to all units
      const allUnits =
        roleOption !== RoleTypes.pro_family
          ? selectedUser.role.all_units === null || selectedUser.role.all_units
          : checkAllUnits(selectedUserUnitIDs, unitIDs);

      let authorizedUnits: string[] = [];
      if (allUnits) {
        authorizedUnits = unitIDs;
      } else {
        if (selectedUser.units) {
          authorizedUnits = selectedUserUnitIDs;
        }
      }
      setAllUnits(allUnits);

      setUserValues({
        ...setUserValues,
        role: roleOption,
        notification_groups: notificationGroups,
        accessible_units: authorizedUnits,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.LAST_ADMIN:
          dispatch(handledError());
          dispatch(showSoftNotification(t("last_admin")));
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, t, dispatch]);

  /* Methods */
  // Picker methods
  const handleOpenFilter = (event: any, popoverID: string) => {
    setAnchorEl(event.currentTarget);
    setPopoverID(popoverID);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  // Menu methods
  const handleOpenMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleDeleteUser = () => {
    setDeleteUser(true);
  };

  const handleResendInvite = async () => {
    setMenuAnchorEl(null);
    if (selectedUser && communityID) {
      await dispatch(
        resendInvitation({
          email: selectedUser.email,
          community_id: communityID,
        })
      );
    }
  };

  const handleCloseDropdown = () => {
    setMenuAnchorEl(null);
    setDeleteUser(false);
  };

  const handleDeleteUserRole = async () => {
    if (selectedUser && communityID) {
      const deleteUserResult = await dispatch(
        deleteCommunityUser({
          userEmail: selectedUser.email,
          community_id: communityID,
        })
      );

      // If deleting our own role, we should change communities
      if (
        deleteCommunityUser.fulfilled.match(deleteUserResult) &&
        selectedUser.email === currentUserEmail
      ) {
        const firstComm = store.getState().headerState.communities?.[0];
        if (firstComm) {
          dispatch(changeSelectedCommunity(firstComm));
        }
      }
      setDeleteUser(false);
      setMenuAnchorEl(null);
    }
  };

  // Component methods
  const getNotificationGroupText = (
    notificationGroups: string[] | null,
    communityGroups?: ICommunityGroup[]
  ) => {
    let notificationGroupDisplay: string[] | undefined = undefined;
    if (notificationGroups !== null) {
      const nameList = notificationGroups.map((notifGroup) => {
        const notifGroupName = communityGroups?.find(
          (communityGroup) => communityGroup.id === notifGroup
        );
        if (notifGroupName !== undefined) {
          return notifGroupName.name ?? "";
        }
        return "";
      });
      // no_notification_groups
      notificationGroupDisplay = nameList.length > 0 ? nameList : undefined;
    }
    return notificationGroupDisplay;
  };

  const getUnitsText = (
    userRole: string | null,
    authorizedUnits: string[],
    allUnits: boolean,
    units?: IUnit[]
  ) => {
    let notificationGroupDisplay = "";
    if (userRole === RoleTypes.pro_family && authorizedUnits.length === 0) {
      return (notificationGroupDisplay = i18n.t("select_unit"));
    }
    if (allUnits && userRole !== RoleTypes.pro_family) {
      return (notificationGroupDisplay = i18n.t("all_units_accessible"));
    }
    if (units !== undefined) {
      const filteredArray = units.filter((option) =>
        authorizedUnits.includes(option.id)
      );

      const nameList = filteredArray.map((unit) => {
        const isHelpAtHomeUnit = isHelpAtHome(unit);

        if (isHelpAtHomeUnit) {
          return unit
            ? getResidentName(unit.residents, {
                fullFirstName: false,
              })
            : "-";
        } else {
          return unit ? unit.name : "-";
        }
      });

      notificationGroupDisplay =
        nameList.length > 0
          ? nameList.sort((a, b) => sortAlphabetical(a, b)).join(", ")
          : i18n.t("no_units_selected");
    } else {
      notificationGroupDisplay = i18n.t("no_units_selected");
    }
    return notificationGroupDisplay;
  };

  const handleRoleChange = (value: string) => {
    if (value === userValues.role) {
      handleCloseFilter();
      return;
    }
    if (value === RoleTypes.pro_family) {
      setUserValues((prevVal) => ({
        ...prevVal,
        role: value,
        notification_groups: [],
        accessible_units: allUnits
          ? []
          : prevVal.accessible_units.length !== 0
          ? prevVal.accessible_units
          : [],
      }));
    } else {
      setUserValues((prevVal) => {
        if (selectedUser === undefined) return { ...prevVal };
        if (communityGroups === undefined) return { ...prevVal };
        if (units === undefined) return { ...prevVal };

        let newAccessibleUnits: string[] = [];
        if (prevVal.accessible_units.length > 0) {
          newAccessibleUnits = prevVal.accessible_units;
        } else {
          setAllUnits(true);
          newAccessibleUnits = units.map((unit) => unit.id);
        }

        return {
          ...prevVal,
          role: value,
          notification_groups:
            prevVal.role === RoleTypes.pro_family
              ? []
              : prevVal.notification_groups,
          accessible_units: newAccessibleUnits,
        };
      });
    }
    handleCloseFilter();
  };

  const handleGroupsChange = (value: string[]) => {
    setUserValues((prevVal) => ({ ...prevVal, notification_groups: value }));
    handleCloseFilter();
  };
  const handleUnitsChange = (value: string[]) => {
    const allSelected = value.length === units?.length;
    setUserValues((prevVal) => ({ ...prevVal, accessible_units: value }));
    setAllUnits(allSelected);
    handleCloseFilter();
  };

  const checkFormChange = (newVals: FormState, oldVals?: IUserDetails) => {
    if (oldVals === undefined) return false;

    let formChanged = false;

    const newRole = newVals.role;
    const oldRole = oldVals.role.name;
    if (newRole !== oldRole) {
      formChanged = true;
      updateRole = true;
    } else {
      updateRole = false;
    }

    const newGroups = newVals.notification_groups;
    const oldGroups = oldVals.groups.map((group) => group.id);

    const hasChangedGroups = !(
      newGroups.sort().join(",") === oldGroups.sort().join(",")
    );

    if (hasChangedGroups) {
      formChanged = true;
      updateUser = true;
    } else {
      updateUser = false;
    }

    const newUnits = allUnits ? [] : newVals.accessible_units;
    const oldUnits =
      oldVals.role.all_units === null || oldVals.role.all_units
        ? []
        : oldVals.units.map((unit) => unit.id); // if it has all selected units, it is []
    const hasChangedUnits = !(
      newUnits.sort().join(",") === oldUnits.sort().join(",")
    );

    if (hasChangedUnits) {
      formChanged = true;
      updateRole = updateRole || true;
    } else {
      updateRole = updateRole || false;
    }

    return formChanged;
  };
  const checkFormValidity = (newVals: FormState, oldVals?: IUserDetails) => {
    if (oldVals === undefined) return false;

    let validForm = true;

    if (newVals.role === RoleTypes.pro_family) {
      validForm = newVals.accessible_units.length > 0;
    }

    return validForm;
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (selectedUser === undefined) return;
    if (communityID === undefined) return;

    let roleData: IUpdateRoleData | undefined = undefined;
    if (updateRole) {
      roleData = {
        email: selectedUser.email,
        communityID: communityID,
        roleData: {
          unit_ids: userValues.accessible_units,
          all_units: allUnits,
          name: userValues.role ?? selectedUser.role.name,
        },
      };
      if (userValues.role === RoleTypes.pro_family) {
        delete roleData.roleData["all_units"];
      } else {
        if (allUnits) {
          delete roleData.roleData["unit_ids"];
        }
      }
    }
    let userData: IUpdateCommunityUserData | undefined = undefined;
    if (updateUser) {
      userData = {
        userID: selectedUser.id,
        userData: {
          group_ids: userValues.notification_groups,
          community_id: communityID,
        },
      };
    }

    const updateCommunityUserData = {
      roleData,
      userData,
    };

    dispatch(updateCommunityUser(updateCommunityUserData));
  };

  const open = Boolean(anchorEl);

  let notificationGroupDisplay = getNotificationGroupText(
    userValues.notification_groups,
    communityGroups
  );

  let authorizedUnitsDisplay = useMemo(
    () =>
      getUnitsText(
        userValues.role,
        userValues.accessible_units,
        allUnits,
        units
      ),
    [allUnits, units, userValues.accessible_units, userValues.role]
  );

  // Show fields conditionally if user is pending or not
  let isNotPending = null;
  if (selectedUser !== undefined) isNotPending = isActiveUser(selectedUser);

  const allGroups =
    userValues.notification_groups?.sort().join(",") ===
    communityGroups
      ?.map((group) => group.id)
      .sort()
      .join(",");

  const enableSubmit =
    checkFormChange(userValues, selectedUser) &&
    checkFormValidity(userValues, selectedUser);

  let lastActiveTime = null;
  if (selectedUser?.last_active_time) {
    lastActiveTime = DateTimeRelativeYearFormat(
      selectedUser?.last_active_time,
      timeZone
    );
  }

  const timeDifference = selectedUser?.last_active_time
    ? DateTimeDiffHoursDays(selectedUser?.last_active_time, t)
    : "";

  return (
    <Paper className={classes.EditUserContainer} elevation={3} square>
      <h2 className={classes.sectionTitle}>
        {t("edit_user")}
        <IconButton
          className={classes.moreButton}
          aria-label="more user options"
          aria-haspopup="true"
          onClick={handleOpenMoreMenu}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="more-menu"
          anchorEl={menuAnchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMoreMenu}
        >
          {!isNotPending && (
            <MenuItem onClick={handleResendInvite}>
              {t("resend_invite")}
            </MenuItem>
          )}
          <MenuItem onClick={handleDeleteUser}>{t("delete_user")}</MenuItem>
        </Menu>
        <ConfirmDialog
          dialogType={DialogTypes.warning}
          toggle={deleteUser}
          title={t("delete_user")}
          message={t("confirm_delete_member")}
          possitiveText={t("yes")}
          negativeText={t("no")}
          positiveAction={handleDeleteUserRole}
          negativeAction={() => void 0}
          onClose={handleCloseDropdown}
        />
      </h2>
      <form
        id="edit-user-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className={classes.formContainer}
      >
        <ul className={classes.wrapper}>
          {isNotPending && (
            <FormRow inputKey="name">
              <span className={classes.rowInput}>{` ${
                selectedUser?.first_name ?? ""
              }  ${selectedUser?.last_name ?? ""}`}</span>
            </FormRow>
          )}
          <FormRow inputKey="email">
            <span className={classes.rowInput}>
              {selectedUser?.email ?? ""}
            </span>
          </FormRow>
          {isNotPending && (
            <FormRow inputKey="last_active">
              <div className={classes.lastActiveBox}>
                <Typography>{lastActiveTime}</Typography>
                <Typography className={classes.timeDifference}>
                  {timeDifference}
                </Typography>
              </div>
            </FormRow>
          )}
          {!isNotPending && (
            <FormRow inputKey="state">
              <Typography>{t("pending")}</Typography>
            </FormRow>
          )}
          <FormRow inputKey="role">
            <Input
              id="edit-community-user-role"
              type={"text"}
              readOnly
              value={
                userValues.role !== null &&
                t(`${roleLabels[userValues.role] ?? ""}`)
              }
              onClick={(e) =>
                !installerRole ? handleOpenFilter(e, "role") : ""
              }
              className={classes.inputWidth}
              disabled={installerRole}
              endAdornment={
                <InputAdornment id="role" position="end">
                  <IconButton
                    aria-label="toggle role picker"
                    disabled={installerRole}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormRow>
          {isNotPending && (
            <FormRow inputKey="phone_numbers">
              <div className={classes.rowInput}>
                {selectedUser?.phone_numbers !== undefined &&
                selectedUser.phone_numbers.length > 0 ? (
                  <React.Fragment>
                    {selectedUser.phone_numbers.map((phoneNumber) => (
                      <PhoneNumberField
                        key={phoneNumber.id}
                        phoneNumber={phoneNumber}
                        notClickable
                      />
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>{t("none")}</React.Fragment>
                )}
              </div>
            </FormRow>
          )}

          <FormRow inputKey="notification_groups">
            <Input
              id="edit-community-user-notification_groups"
              type={"text"}
              readOnly
              disabled={userValues.role === RoleTypes.pro_family}
              className={classes.inputWidth}
              onClick={
                userValues.role !== RoleTypes.pro_family
                  ? (e) => handleOpenFilter(e, "notification_groups")
                  : undefined
              }
              value={
                !allGroups
                  ? notificationGroupDisplay !== undefined
                    ? notificationGroupDisplay.join(", ")
                    : t("no_notification_groups")
                  : t("all_notification_groups")
              }
              endAdornment={
                <InputAdornment id="notification_groups" position="end">
                  <IconButton
                    disabled={userValues.role === RoleTypes.pro_family}
                    aria-label="toggle notification groups picker"
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormRow>
          <FormRow inputKey="accessible_units">
            <Input
              id="edit-community-user-notification_groups"
              type={"text"}
              readOnly
              error={
                userValues.role === RoleTypes.pro_family &&
                userValues.accessible_units.length === 0
              }
              onClick={(e) => handleOpenFilter(e, "accessible_units")}
              value={authorizedUnitsDisplay}
              className={classes.inputWidth}
              endAdornment={
                <InputAdornment id="accessible_units" position="end">
                  <IconButton aria-label="toggle accessible units picker">
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormRow>
        </ul>
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            variant={!enableSubmit ? "outlined" : "contained"}
            color="primary"
            disabled={!enableSubmit}
            classes={{
              root: classes.buttonRoot,
            }}
          >
            {t("save")}
          </Button>
        </div>
      </form>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 1200 }}
      >
        <React.Fragment>
          {popoverID === "role" && (
            <RolePicker
              value={userValues.role}
              userRoles={userRoles}
              handleChange={handleRoleChange}
            />
          )}
          {popoverID === "notification_groups" && (
            <NotificationGroupsPicker
              value={userValues.notification_groups}
              communityGroups={communityGroups}
              handleChange={handleGroupsChange}
            />
          )}
          {popoverID === "accessible_units" && (
            <AccessibleUnitsPicker
              value={userValues.accessible_units}
              units={units ?? []}
              atLeastOne={userValues.role === RoleTypes.pro_family}
              handleChange={handleUnitsChange}
            />
          )}
          {popoverID === "" && null}
        </React.Fragment>
      </Popover>
    </Paper>
  );
}
