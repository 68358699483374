import { request } from "./utils";
import { IFailedRequest } from "./login.services";
import { IZone } from "./dashboard.services";

export const devicesServices = {
  getDeviceIssues,
  getDevicesData,
  getGatewaysData,
  getDevicePlacementPhoto,
  requestForLQIUpdate,
  requestToIdentifyDevice,
  deleteDevice,
  updateZoneName,
  deletePlacementPhoto,
};

export interface DeviceIssue {
  unit_id: string;
  hub_status?: string;
  low_battery_devices?: number;
  offline_devices?: number;
}

export interface Device {
  id: string;
  mac_address: string;
  device_type?: string | null;
  zone_id?: string | null;
  zone_name?: string | null;
  sensor_type?: string | null;
  is_connected?: boolean | null;
  last_contact_time?: string | null;
  last_motion_time?: string | null;
  battery_percentage_remaining?: number | null;
  battery_voltage?: number | null;
  lqi_value?: number | null;
  battery_state?: "good" | "low" | null;
  model?: string | null;
  firmware_vs?: string | null;
  third_party_id?: string | null;
  status?: string | null;
  manufacturer?: string | null;
  state?: boolean | null;
}

export interface Gateway {
  id: string;
  mac_address: string;
  model?: string | null;
  is_connected?: boolean | null;
  last_contact_time?: string | null;
  connection_type?: "wifi" | "lte" | null;
  ip?: string | null;
  lte_rssi?: number | null;
  lte_imei?: string | null;
  lte_imsi?: string | null;
  lte_cell_mode?: string | null;
  lte_rsrp?: number | null;
  lte_rsrq?: number | null;
  lte_sinr?: number | null;
  lte_module?: string | null;
  lte_operator?: string | null;
  wifi_rssi?: number | null;
  wifi_ssid?: string | null;
  wifi_ap_bssid: string | null;
  wifi_channel_primary?: number | null;
  wifi_mac_address: string | null;
}

export interface IRequestUpdateLQIData {
  unitID: string;
  gatewayID: string;
}

export interface IRequestIdentifyData {
  unitID: string;
  gatewayID: string;
  deviceID: string;
}

export interface IRequestDeleteDeviceData {
  unitID: string;
  deviceID: string;
}

export interface IUpdateZoneData {
  unitID: string;
  zoneID: string;
  zoneName: string;
}

async function getDeviceIssues(communityID: string): Promise<DeviceIssue[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(
    `/communities/${communityID}/device_issues`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as DeviceIssue[]);
  }
}

async function getDevicesData(siteID: string): Promise<Device[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(`/sites/${siteID}/sensors`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as Device[]);
  }
}

async function getGatewaysData(siteID: string): Promise<Gateway[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(`/sites/${siteID}/hubs`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as Gateway[]);
  }
}

async function getDevicePlacementPhoto(deviceID: string): Promise<string> {
  const requestOptions = {
    body: null,
    responseType: "blob",
  };

  const data = await request(
    `/placement_photos?device_id=${deviceID}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    const imgUrl = URL.createObjectURL(data);

    return Promise.resolve(imgUrl as string);
  }
}

async function deletePlacementPhoto(deviceID: string): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "DELETE",
    body: null,
  };
  const data = await request(
    `/placement_photos?device_id=${deviceID}`,
    requestOptions
  );
  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as boolean);
  }
}

async function requestForLQIUpdate(
  requestData: IRequestUpdateLQIData
): Promise<boolean> {
  const { unitID, gatewayID } = requestData;
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      command: "request_lqi",
    }),
  };

  const data = await request(
    `/sites/${unitID}/hubs/${gatewayID}/command`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as boolean);
  }
}

async function requestToIdentifyDevice(
  requestData: IRequestIdentifyData
): Promise<boolean> {
  const { unitID, gatewayID, deviceID } = requestData;
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      command: "identify",
      device_id: deviceID,
      duration: "120",
    }),
  };

  const data = await request(
    `/sites/${unitID}/hubs/${gatewayID}/command`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as boolean);
  }
}

async function deleteDevice(
  requestData: IRequestDeleteDeviceData
): Promise<boolean> {
  const { unitID, deviceID } = requestData;
  const requestOptions: RequestInit = {
    method: "DELETE",
    body: null,
  };

  const data = await request(
    `/sites/${unitID}/sensors/${deviceID}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as boolean);
  }
}

async function updateZoneName(requestData: IUpdateZoneData): Promise<IZone> {
  const { unitID, zoneID, zoneName } = requestData;
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      name: zoneName,
    }),
  };

  const data = await request(
    `/sites/${unitID}/zones/${zoneID}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IZone);
  }
}
